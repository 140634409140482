import React from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import {useNews} from '../../Hooks/useNews';
import Spinner from '../../Components/Spinner';
import ArticlePreview from '../../Components/RichTextPreview';

const NewsDetail = (props) => {
  const {location} = props;
  const {id, app} = qs.parse(location.search);
  const {news, isLoading} = useNews(id);
  const isApp = app === '1';

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Wrapper isApp={isApp}>
      <ArticlePreview content={news.content || []} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: ${({isApp}) => {
    return isApp ? `0 auto 0 auto;` : `var(--topNavBarHeight) auto 0 auto;`;
  }};
  max-width: var(--contentMaxWith);
  padding: var(--basePadding);
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--topNavBarHeight));
`;

export default NewsDetail;
