import {useEffect, useState} from 'react';
import {useOutlet} from 'reconnect.js';

export const useNews = (id) => {
  const [news, setNews] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [actions] = useOutlet('actions');

  useEffect(() => {
    const fetchNews = async () => {
      try {
        setIsLoading(true);
        const resp = await actions.fetchNews(id);
        setNews(resp);
      } catch (e) {
        console.log('debug', e);
      } finally {
        setIsLoading(false);
      }
    };
    fetchNews().then(() => 0);
  }, [actions, id]);

  return {
    isLoading,
    news,
  };
};
